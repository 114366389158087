import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Visibility, Rse } from "./";
import Place from "models/places";

const PlaceEntity = new Place();

const Settings = ({ datas, setDatas }) => {
	const { t } = useTranslation();
	const save = async (d) => {
		if (datas?.id) {
			Object.assign(PlaceEntity, d);
			await PlaceEntity.updatePlace(datas?.id);
			setDatas(d);
		}
	};
	return (
		<>
			<Visibility
				sectionsVisibility={datas?.sectionsVisibility || []}
				setDatas={(d) => save({ ...datas, sectionsVisibility: d })}
			/>

			<Rse
				actionsRse={datas?.actions_rse || []}
				setDatas={(d) => save({ ...datas, actions_rse: d })}
			/>
		</>
	);
};

export default Settings;
Settings.propTypes = {};
Settings.defaultProps = {};
