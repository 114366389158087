import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Switch,
  Input,
  Button,
  FormLabel,
  Sheet,
  ModalOverflow,
  FormControl,
  Modal,
  ModalDialog,
  DialogTitle,
  Box,
  Stack,
  Checkbox,
  Typography,
} from "@mui/joy";
import { Image } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AddSimpleImage } from "UI";
import bedTypes from "datas/places/places_bed_type.json";

const BedRoomModal = ({ isOpen, onClose, onSave, defaultValues }) => {
  const { t } = useTranslation();
  const { register, control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: defaultValues || {
      nom: "",
      description: "",
      nbr_pers: "",
      size: "",
      qty_available: "",
      image: null,
      bed_type: [], // Array to store selected bed types
    },
  });

  let image = watch("image");

  React.useEffect(() => {
    reset({});
    if (isOpen && defaultValues) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const onSubmit = (data) => {
    onSave(data);
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
          reset({});
        }}
      >
        <ModalOverflow>
          <ModalDialog size="lg" sx={{ width: "80%" }}>
            <DialogTitle>
              {defaultValues?.nom ? (
                <p>
                  {t("bedroom")}: <i>{defaultValues.nom}</i>
                </p>
              ) : (
                <p>{t("new_bedroom")}</p>
              )}
            </DialogTitle>

            <Sheet>
              <form>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid #eee",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack spacing={2}>
                      <FormControl variant="outlined">
                        <FormLabel>{t("bed_room_name")}</FormLabel>
                        <Input
                          {...register("nom")}
                          label={t("bed_room_name")}
                        />
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>{t("bed_room_desc")}</FormLabel>
                        <Input
                          {...register("description")}
                          label={t("bed_room_desc")}
                        />
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>{t("nbr_pers_room")}</FormLabel>
                        <Input
                          type="number"
                          {...register("nbr_pers")}
                          label={t("nbr_pers_room")}
                        />
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>{t("size_room")}</FormLabel>
                        <Input
                          type="number"
                          {...register("size")}
                          label={t("size_room")}
                        />
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>{t("qty_available_room")}</FormLabel>
                        <Input
                          type="number"
                          {...register("qty_available")}
                          label={t("qty_available_room")}
                        />
                      </FormControl>
                      {/* Bed Type Selection with Checkboxes */}
                      <FormControl variant="outlined">
                        <FormLabel>{t("bed_type")}</FormLabel>
                        <Stack spacing={1}>
                          {bedTypes.map((type) => (
                            <Controller
                              key={type.label}
                              name="bed_type"
                              control={control}
                              render={({ field }) => (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Checkbox
                                    checked={
                                      field.value
                                        ? field.value.includes(type.label)
                                        : false
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.checked
                                        ? [...(field.value || []), type.label]
                                        : field.value.filter(
                                            (item) => item !== type.label,
                                          );
                                      field.onChange(newValue);
                                    }}
                                  />
                                  <Typography sx={{ ml: 1 }}>
                                    {t(type.label)}
                                  </Typography>
                                </Box>
                              )}
                            />
                          ))}
                        </Stack>
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>{t("bed_room_pic")}</FormLabel>
                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                          <AddSimpleImage
                            style={{
                              maxWidth: "300px",
                              width: "100%",
                              height: "180px",
                              p: 2,
                              textAlign: "center",
                            }}
                            label={t("add_featured_image")}
                            icon={<Image />}
                            remove={() => setValue("image", null)}
                            action={(img) => setValue("image", img)}
                            image={image}
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                  </Box>

                  <Button type="button" onClick={handleSubmit(onSubmit)}>
                    Sauvegarder
                  </Button>
                </Stack>
              </form>
            </Sheet>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
};

export default BedRoomModal;
