import React, { useState } from "react";
import {
  Typography,
  FormControl,
  FormLabel,
  Box,
  Chip,
  Button,
  Textarea,
  Stack,
  Divider,
  FormHelperText,
  Input,
  Tooltip,
  IconButton,
  Table,
  Sheet,
} from "@mui/joy";
import { Edit, Delete, Add, Save } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import materiel_technique from "datas/places/places_technical_equipment.json";
import confSalle from "datas/places/places_conf_salle.js";

const options = ["lumiereDuJour", "AccesPmr"]; // Options de la salle

const Salles = ({ setValue, datas, errors, register, control }) => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState(datas);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newRoom, setNewRoom] = useState(null);

  const { handleSubmit } = useForm();

  React.useEffect(() => {
    if (rooms.length < 1 && datas.length > 0) {
      setRooms(datas);
    }
  }, [datas]);

  const updateValues = (updatedRooms) => {
    setValue("rooms", updatedRooms, { shouldDirty: true });
    setRooms(updatedRooms);
  };

  const handleDelete = (index) => {
    const newRooms = rooms.filter((_, i) => i !== index);
    updateValues(newRooms);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleSave = (index, roomData) => {
    const updatedRooms = rooms.map((room, i) =>
      i === index ? { ...room, ...roomData } : room,
    );
    updateValues(updatedRooms);
    setEditingIndex(null);
  };

  const handleAddRoom = () => {
    const newRoomData = {
      id: uuidv4(),
      nom_salle: "",
      capacite_max: "",
      superficie_salles: "",
      configurations: [
        { type: "cocktail", capacite: 0 },
        { type: "enConference", capacite: 0 },
        { type: "theatre", capacite: 0 },
        { type: "enRangDEcole", capacite: 0 },
        { type: "tableRondeGala", capacite: 0 },
        { type: "enRond", capacite: 0 },
        { type: "enReunion", capacite: 0 },
        { type: "enU", capacite: 0 },
      ],
      options: {
        lumiereDuJour: false,
        AccesPmr: false,
      },
    };
    setNewRoom(newRoomData);
  };

  const handleSaveNewRoom = (roomData) => {
    const updatedRooms = [...rooms, { id: uuidv4(), ...roomData }];
    updateValues(updatedRooms);
    setNewRoom(null);
  };

  return (
    <Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
      <Typography sx={{ mb: 2 }} level="h4" component="h2">
        {t("meeting_rooms")}
      </Typography>
      <Stack spacing={3}>
        {/* Description */}
        <FormControl variant="outlined">
          <FormLabel>Description des salles</FormLabel>
          <Textarea
            {...register("description_salles")}
            placeholder="Décrivez les salles"
            multiline
            minRows={3}
          />
          <FormHelperText>{errors?.description_salles?.message}</FormHelperText>
        </FormControl>
        {/* Nbr salle */}
        <FormControl variant="outlined">
          <FormLabel>{t("nbr_salle")}</FormLabel>
          <Stack direction="row" spacing={2}>
            <Input
              {...register("nbr_salle_plus_50")}
              placeholder={t("nbr_salle_plus_50")}
              type="number"
            />
            <Input
              {...register("nbr_salle_minus_50")}
              placeholder={t("nbr_salle_minus_50")}
              type="number"
            />
          </Stack>
          <FormHelperText>{errors?.nbr_salle?.message}</FormHelperText>
        </FormControl>

        {/* EQUIPEMENT */}
        <Box sx={{ mt: 2 }}>
          <FormLabel sx={{ mb: 2 }}>
            Liste des équipements disponibles.
          </FormLabel>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {materiel_technique.map((option) => (
              <Controller
                key={option}
                name={`equipement_technique`}
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <Chip
                    variant={value.includes(option) ? "solid" : "outlined"}
                    color={value.includes(option) ? "primary" : "default"}
                    onClick={() => {
                      const newValue = value.includes(option)
                        ? value.filter((v) => v !== option)
                        : [...value, option];
                      onChange(newValue);
                    }}
                    label={option}
                    size="md"
                    sx={{
                      cursor: "pointer",
                      border: "1px solid #eee",
                      "&:hover": {
                        opacity: 0.8,
                      },
                    }}
                  >
                    {t(option)}
                  </Chip>
                )}
              />
            ))}
          </Box>
        </Box>
      </Stack>
      <Divider sx={{ m: 2 }} />
      {/* Table with Editable Rows */}
      <div>
        <Typography sx={{ mb: 1, mt: 2 }} component="h4">
          Liste des salles
        </Typography>
        <Sheet sx={{ overflow: "auto", width: "100%" }}>
          <Table
            aria-label="table with sticky header"
            stickyHeader
            stickyFooter
            sx={{}}
            stripe="odd"
            hoverRow
          >
            <thead>
              <tr>
                <th>
                  <Tooltip title={t("Nom de la salle")}>
                    <span>{t("Nom de la salle")}</span>
                  </Tooltip>
                </th>
                <th>
                  <Tooltip title={t("Capacité Max")}>
                    <span>{t("Capacité Max")}</span>
                  </Tooltip>
                </th>
                <th>
                  <Tooltip title={t("Surface")}>
                    <span>{t("Surface")}</span>
                  </Tooltip>
                </th>
                {Object.keys(confSalle).map((k) => (
                  <th key={k}>
                    <Tooltip title={t(k)}>
                      <span>{confSalle[k]}</span>
                    </Tooltip>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rooms.map((room, index) => (
                <tr key={room.id}>
                  <td>
                    {editingIndex === index ? (
                      <Input
                        defaultValue={room.nom_salle}
                        onChange={(e) => (room.nom_salle = e.target.value)}
                      />
                    ) : (
                      room.nom_salle
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <Input
                        type="number"
                        defaultValue={room.capacite_max}
                        onChange={(e) => (room.capacite_max = e.target.value)}
                      />
                    ) : (
                      room.capacite_max
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <Input
                        type="number"
                        defaultValue={room.superficie_salles}
                        onChange={(e) =>
                          (room.superficie_salles = e.target.value)
                        }
                      />
                    ) : (
                      room.superficie_salles
                    )}
                  </td>
                  {room.configurations.map((config, configIndex) => (
                    <td key={config.type}>
                      {editingIndex === index ? (
                        <Input
                          type="number"
                          defaultValue={config.capacite}
                          onChange={(e) =>
                            (room.configurations[configIndex].capacite =
                              e.target.value)
                          }
                        />
                      ) : (
                        config.capacite || "n/c"
                      )}
                    </td>
                  ))}
                  <td>
                    {editingIndex === index
                      ? options.map((opt) => (
                          <Chip
                            key={opt}
                            label={opt}
                            color={room.options[opt] ? "primary" : "default"}
                            onClick={() => {
                              const updatedOptions = {
                                ...room.options,
                                [opt]: !room.options[opt],
                              };
                              const updatedRoom = {
                                ...room,
                                options: updatedOptions,
                              };
                              setRooms((prevRooms) =>
                                prevRooms.map((r, i) =>
                                  i === index ? updatedRoom : r,
                                ),
                              );
                            }}
                            sx={{
                              cursor: "pointer",
                              border: "1px solid #eee",
                              "&:hover": { opacity: 0.8 },
                            }}
                          >
                            {t(opt)}
                          </Chip>
                        ))
                      : options.map(
                          (opt) =>
                            room.options[opt] && (
                              <Chip key={opt} label={opt}>
                                {t(opt)}
                              </Chip>
                            ),
                        )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <IconButton
                        onClick={() => handleSave(index, room)}
                        variant="outlined"
                      >
                        <Save />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEdit(index)}>
                        <Edit />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(index)}>
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              ))}
              {newRoom && (
                <tr>
                  <td>
                    <Input
                      placeholder="Nom de la salle"
                      onChange={(e) => (newRoom.nom_salle = e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      placeholder="Capacité max"
                      onChange={(e) => (newRoom.capacite_max = e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      placeholder="Superficie"
                      onChange={(e) =>
                        (newRoom.superficie_salles = e.target.value)
                      }
                    />
                  </td>
                  {newRoom.configurations.map((config, configIndex) => (
                    <td key={config.type}>
                      <Input
                        type="number"
                        placeholder={`Capacité ${config.type}`}
                        onChange={(e) =>
                          (newRoom.configurations[configIndex].capacite =
                            e.target.value)
                        }
                      />
                    </td>
                  ))}
                  <td>
                    {options.map((opt) => (
                      <Chip
                        key={opt}
                        label={opt}
                        color={newRoom.options[opt] ? "primary" : "default"}
                        onClick={() => {
                          const updatedOptions = {
                            ...newRoom.options,
                            [opt]: !newRoom.options[opt],
                          };
                          setNewRoom({ ...newRoom, options: updatedOptions });
                        }}
                        sx={{
                          cursor: "pointer",
                          border: "1px solid #eee",
                          "&:hover": { opacity: 0.8 },
                        }}
                      >
                        {t(opt)}
                      </Chip>
                    ))}
                  </td>
                  <td>
                    <IconButton
                      onClick={() =>
                        handleSubmit((data) => handleSaveNewRoom(newRoom))()
                      }
                      variant="outlined"
                    >
                      <Save />
                    </IconButton>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Sheet>
        <Button onClick={handleAddRoom} startDecorator={<Add />}>
          Ajouter une salle
        </Button>
      </div>
    </Box>
  );
};

export default Salles;
