import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Stack,
	Sheet,
	Table,
	Tooltip,
	List,
	ListItem,
} from "@mui/joy";
import PropTypes from "prop-types";
import confSalle from "datas/places/places_conf_salle.js";

const View = ({
	adresse,
	code_postal,
	description,
	nom,
	pays,
	nbr_salle_plus_50,
	nbr_salle_minus_50,
	region,
	ville,
	type_lieu,
	rooms,
	featured_image,
	nombre_couchage_indiv,
	capacite_en_twin,
	nombre_couchage_max,
	nombre_de_chambres,
}) => {
	const { t } = useTranslation();
	const bedrooms = [
		{ label: "nombre_couchage_indiv", data: nombre_couchage_indiv || null },
		{ label: "capacite_en_twin", data: capacite_en_twin || null },
		{ label: "nombre_couchage_max", data: nombre_couchage_max || null },
		{ label: "nombre_de_chambres", data: nombre_de_chambres || null },
		{ label: "nbr_salle_minus_50", data: nbr_salle_minus_50 || null },
		{ label: "nbr_salle_plus_50", data: nbr_salle_plus_50 || null },
	];
	return (
		<>
			<Box
				sx={{
					mb: 3,
					height: 200,
					width: 300,
					borderRadius: 5,
					background: `url("${featured_image}") no-repeat center center / cover`,
				}}
			></Box>

			<List>
				{bedrooms.map((r) => (
					<ListItem>
						{t(r.label)} : {r.data}
					</ListItem>
				))}
			</List>

			{rooms && (
				<>
					<Sheet
						variant="outlined"
						sx={{
							mb: 3,
							mt: 2,
							"--TableCell-height": "40px",
							// the number is the amount of the header rows.
							"--TableHeader-height": "calc(1 * var(--TableCell-height))",
							"--Table-firstColumnWidth": "80px",
							"--Table-lastColumnWidth": "144px",
							// background needs to have transparency to show the scrolling shadows
							"--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
							"--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
							overflow: "auto",
							background: (theme) =>
								`linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
							backgroundSize:
								"40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
							backgroundRepeat: "no-repeat",
							backgroundAttachment: "local, local, scroll, scroll",
							backgroundPosition:
								"var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
							backgroundColor: "background.surface",
						}}
					>
						<Table
							borderAxis="bothBetween"
							stripe="odd"
							hoverRow
							sx={{
								"& tr > *:first-child": {
									position: "sticky",
									left: 0,
									boxShadow: "1px 0 var(--TableCell-borderColor)",
									bgcolor: "background.surface",
								},
							}}
						>
							<thead>
								<tr>
									<th>Nom de la salle</th>
									<th>Capacité Max</th>
									<th>Surface</th>
									{Object.keys(confSalle).map((key) => (
										<th style={{ textAlign: "center" }} key={key}>
											<Tooltip title={t(key)}>
												<span>{confSalle?.[key]}</span>
											</Tooltip>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{rooms.map((room) => (
									<tr key={room.id}>
										<td>{room.nom_salle}</td>
										<td>{room.capacite_max}</td>
										<td>{room.superficie_salles}</td>
										{room.configurations.map((config) => (
											<td key={config.type}>
												{parseInt(config.capacite) || "n/c"}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</>
			)}
			<Stack spacing={2} sx={{}}>
				<Typography>
					<b>{t("name")} : </b>
					{nom}
				</Typography>

				<Typography>
					<b> {t("description ")}: </b>
					{description}
				</Typography>

				<Typography>
					<b> {t("address")}: </b>
					{adresse}
				</Typography>
				<Typography>
					<b> {t("place_type")}: </b>
					{t(type_lieu)}
				</Typography>
			</Stack>
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
