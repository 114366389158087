import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { ArrowBack, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CreateModelFrom } from "../.";
import useAuthContainer from "stores/Auth";

const Header = ({ placeID, createModel, datas }) => {
	const { t } = useTranslation();
	const {
		user: { uid },
		userAccount,
	} = useAuthContainer.useContainer();

	return uid == datas.data()?.uid || userAccount?.role === "admin" ? (
		<Stack direction="row" sx={{ mt: 2 }} justifyContent="space-between">
			<Box>
				<Button
					variant="outlined"
					sx={{ mb: 3, textDecoration: "none" }}
					component={Link}
					to="/places"
					direction="row"
				>
					<ArrowBack />
					<Typography>Retour à la liste des lieux</Typography>
				</Button>
				<Button
					sx={{ mb: 3, textDecoration: "none" }}
					component={Link}
					to={`/places/edit/${placeID}`}
					direction="row"
				>
					<Edit />
					<Typography sx={{ color: "white" }}>Modifier</Typography>
				</Button>
			</Box>
			<CreateModelFrom
				placeDatas={{ ...datas.data(), id: datas.id }}
				createModel={createModel}
			/>
		</Stack>
	) : (
		<Stack direction="row" justifyContent="space-between">
			<Button
				variant="outlined"
				sx={{ mb: 3, textDecoration: "none" }}
				component={Link}
				to="/places/search"
				direction="row"
			>
				<ArrowBack />
				<Typography>Retour à la liste des lieux</Typography>
			</Button>
		</Stack>
	);
};

export default Header;
Header.propTypes = {
	placeID: PropTypes.string,
	datas: PropTypes.object,
};
Header.defaultProps = {};
