import React from "react";
import {
	ImageSlider,
	Title,
	Description,
	Rooms,
	Rse,
	Hebergement,
	MeetingRoomResume,
	Tags,
	MaxConfigSalle,
	Espaces,
	TeamBuilding,
	CadreAmbiance,
	Restauration,
	RoomsResume,
	CreateModelFrom,
	Activites,
	FeaturedHeader,
	Tarifs,
	NbrSalles,
	Header,
	WeevupCallToAction,
} from "./partials";
import RouteMap from "../../../../components/common/RouteMap/RouteMap";
import {
	Grid,
	Button,
	Sheet,
	Typography,
	Stack,
	Box,
	Divider,
	Container,
} from "@mui/joy";
import { ArrowBack, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { db } from "../../../../utils/MyFirebase"; // Assurez-vous que le chemin est correct
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useDocument } from "react-firebase-hooks/firestore";
import StickyBox from "react-sticky-box";
import useAuthContainer from "stores/Auth";
import { HideSectionButton } from "forms/places";

function PlaceView({ setDocId }) {
	const { user, userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();

	const history = useHistory();

	const { placeID } = useParams();

	const [value, loading, error] = useDocument(
		placeID ? doc(db, "places", placeID) : null,
	);
	React.useEffect(() => {
		setDocId(placeID);
	}, [placeID]);

	const isHidden = (section) => {
		const uid = value.data()?.uid;
		const sectionsVisibility = value.data()?.sectionsVisibility;
		return uid === user?.uid ||
			userAccount?.role === "admin" ||
			!sectionsVisibility?.includes(section)
			? "block"
			: "none";
	};

	const createModel = async (datas) => {
		try {
			const docRef = await addDoc(collection(db, "events"), datas);
			history.push(`/event/${docRef.id}/general`);
		} catch (e) {
			console.error("Erreur lors de l'ajout du document : ", e);
		}
	};

	return loading ? (
		"...Loading"
	) : value ? (
		<Box sx={{ pt: 10, background: "white" }}>
			<Header placeID={placeID} createModel={createModel} datas={value} />
			<Sheet sx={{ p: 2, background: "white" }}>
				<FeaturedHeader image={value.data()?.featured_image}>
					<ImageSlider
						images={value.data()?.images}
						featuredImage={value.data()?.featuredImage}
					/>
				</FeaturedHeader>
				<Container maxWidth="xl" sx={{ background: "white" }}>
					<Grid container>
						<Grid md={8}>
							<Title {...value.data()} />
							<CadreAmbiance cadres={value.data()?.cadre || []} />
							<Tags tags={value.data()?.tags} />

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									flexDirection: "row",
									gap: 5,
									flexWrap: "wrap",
									mb: 2,
									mt: 4,
								}}
							>
								{/*							<MeetingRoomResume salles={value.data()?.rooms} />
								 */}{" "}
							</Box>

							<Description {...value.data()} />
							<Divider sx={{ m: 3 }} />
							<Box sx={{ display: isHidden("rse") }}>
								<Rse
									actions_rse={
										value.data()?.actions_rse || null
									}
									description_rse={
										value.data()?.description_rse
									}
									label_rse={value.data()?.label_rse}
									placeId={placeID}
									sections={
										value.data()?.sectionsVisibility || []
									}
									uid={value.data()?.uid}
								/>
							</Box>
							<Sheet
								sx={{
									display: isHidden("chambres"),
									p: 2,
									background: "white",
								}}
							>
								<Hebergement
									placeId={placeID}
									{...value.data()}
								/>
							</Sheet>

							<Sheet sx={{ p: 2, background: "white" }}>
								<Rooms {...value.data()} />
							</Sheet>
							<Sheet
								sx={{
									display: isHidden("espaces"),
									p: 2,
									background: "white",
								}}
							>
								<Espaces
									placeId={placeID}
									sections={
										value.data()?.sectionsVisibility || []
									}
									uid={value.data()?.uid}
									espaces={value.data()?.espaces}
								/>
							</Sheet>
							<Sheet
								sx={{
									p: 2,
									background: "white",
									display: isHidden("activites"),
								}}
							>
								<Activites
									placeId={placeID}
									uid={value.data()?.uid}
									sections={
										value.data()?.sectionsVisibility || []
									}
									activites={value.data()?.activites_loisir}
								/>
							</Sheet>
							<Sheet
								sx={{
									display: isHidden("restauration"),
									p: 2,
									background: "white",
								}}
							>
								<Restauration
									placeId={placeID}
									sections={
										value.data()?.sectionsVisibility || []
									}
									restauration={value.data()?.restauration}
									uid={value.data()?.uid}
								/>
							</Sheet>
							<Sheet
								sx={{
									display: isHidden("team_building"),
									p: 2,
									background: "white",
								}}
							>
								<TeamBuilding
									placeId={placeID}
									sections={
										value.data()?.sectionsVisibility || []
									}
									uid={value.data()?.uid}
									team_building={value.data()?.team_building}
								/>
							</Sheet>
						</Grid>
						<Grid
							md={4}
							sx={{ pl: 2, display: { xs: "none", sm: "block" } }}
						>
							<StickyBox offsetTop={200} offsetBottom={20}>
								<Box
									sx={{
										top: "-100px",
										position: "relative",
										background: "white",
										p: 1,
										boxShadow: "0px 0px 5px #00000025",
										borderRadius: "5px",
									}}
								>
									<Stack spacing={3} sx={{ ml: 2 }}>
										<Typography
											level="h4"
											sx={{ textAlign: "center" }}
											color="primary"
										>
											{value.data()?.nom}
										</Typography>
										<Divider sx={{ m: 1, mt: 3 }} />
										<Typography
											level="h5"
											sx={{ textAlign: "center" }}
											color="primary"
										>
											{t("les_salles")}
										</Typography>
										<NbrSalles {...value.data()} />
										<MaxConfigSalle
											salles={value.data()?.rooms}
										/>

										<Divider sx={{ m: 1, mt: 3 }} />

										<RoomsResume {...value.data()} />

										<Divider sx={{ m: 1 }} />
										<Box
											sx={{ display: isHidden("prices") }}
										>
											<HideSectionButton
												section="prices"
												placeId={placeID}
												uid={value.data()?.uid}
												sectionsVisibility={
													value.data()
														?.sectionsVisibility ||
													[]
												}
											/>
											<Tarifs
												price={
													value.data()
														?.tarifs_journee_etude
												}
												type="journee_etude"
											/>

											<Tarifs
												price={
													value.data()
														?.tarifs_seminaire_residentiel
												}
												type="seminaire_residentiel"
											/>
										</Box>
									</Stack>
									<WeevupCallToAction place_id={placeID} />
								</Box>
							</StickyBox>
						</Grid>
					</Grid>
					<RouteMap
						destination={value.data()?.coordonnees_gps || undefined}
					/>
				</Container>
			</Sheet>
		</Box>
	) : null;
}

export default PlaceView;
