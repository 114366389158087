import React from "react";
import {
  Table,
  Typography,
  Sheet,
  Box,
  Tooltip,
  Chip,
  List,
  ListItem,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import salleConfig from "datas/places/places_conf_salle.js";
import salleConfigOptions from "datas/places/places_conf_salle_options.js";
import Helpers from "utils/Helpers";
import Icons from "assets/icons/Icons";
const Rooms = ({
  rooms,
  description_salles,
  equipement_technique,
  nbr_salle_plus_50,
  nbr_salle_minus_50,
}) => {
  const { t } = useTranslation();
  let equipement = [];
  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        level="h4"
        sx={(theme) => ({
          mb: 2,
          textAlign: "left",
          color: theme.vars.palette.primary[600],
        })}
      >
        {t("meeting_rooms")}
      </Typography>
      <Typography sx={{ whiteSpace: "pre-wrap" }} paragraph variant="body2">
        {description_salles}
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: !nbr_salle_plus_50 && !nbr_salle_minus_50 && "none",
        }}
      >
        <Typography
          level="h5"
          sx={(theme) => ({
            mb: 2,
            textAlign: "left",
            color: theme.vars.palette.primary[600],
          })}
        >
          {t("nbr_salle")}
        </Typography>
        <Box>
          <List>
            <ListItem sx={{ display: !nbr_salle_plus_50 && "none" }}>
              <Typography>
                <b>{t("nbr_salle_plus_50")}</b> : {nbr_salle_plus_50}
              </Typography>
            </ListItem>
            <ListItem sx={{ display: !nbr_salle_minus_50 && "none" }}>
              <Typography>
                <b>{t("nbr_salle_minus_50")}</b> : {nbr_salle_minus_50}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography
          level="h5"
          sx={(theme) => ({
            mb: 2,
            textAlign: "left",
            color: theme.vars.palette.primary[600],
          })}
        >
          {t("equipement_technique")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {equipement_technique &&
            Helpers.checkIsArray(equipement_technique).map((equip) => (
              <Chip>{t(equip)}</Chip>
            ))}
        </Box>
      </Box>
      {rooms && (
        <>
          <Typography
            level="h5"
            sx={(theme) => ({
              mt: 2,
              textAlign: "left",
              color: theme.vars.palette.primary[600],
            })}
          >
            {t("room_detail")}
          </Typography>
          <Sheet
            variant="outlined"
            sx={{
              mt: 2,
              "--TableCell-height": "40px",
              // the number is the amount of the header rows.
              "--TableHeader-height": "calc(1 * var(--TableCell-height))",
              "--Table-firstColumnWidth": "80px",
              "--Table-lastColumnWidth": "144px",
              // background needs to have transparency to show the scrolling shadows
              "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
              "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
              overflow: "auto",
            }}
          >
            <Table
              borderAxis="bothBetween"
              stripe="odd"
              hoverRow
              sx={{
                "& tr > *:first-child": {
                  position: "sticky",
                  left: 0,
                  boxShadow: "1px 0 var(--TableCell-borderColor)",
                  bgcolor: "background.surface",
                  width: 200,
                },
              }}
            >
              <thead>
                <tr>
                  <th>
                    <Tooltip title={t("Nom de la salle")}>
                      <Typography>{t("Nom de la salle")}</Typography>
                    </Tooltip>
                  </th>

                  <th>
                    <Tooltip title={t("Capacité Max")}>
                      <>
                        <Box>
                          <Icons
                            width="60"
                            name="Pictos Weevapp_Capacité max debout"
                          />
                        </Box>
                        <Typography>{t("Capacité Max")}</Typography>
                      </>
                    </Tooltip>
                  </th>

                  <th>
                    <Tooltip title={t("Surface")}>
                      <>
                        {" "}
                        <Box>
                          <Icons
                            width="60"
                            name="Pictos Weevapp_Surface max "
                          />
                        </Box>
                        <Typography>{t("Surface")}</Typography>
                      </>
                    </Tooltip>
                  </th>

                  {Object.keys(salleConfig).map((configKey) => (
                    <th
                      style={{
                        whiteSpace: "normal",
                        width: "150px",
                      }}
                      key={configKey}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Tooltip title={t(configKey)}>
                          <span>{salleConfig[configKey]}</span>
                        </Tooltip>
                        <Typography
                          sx={{
                            mt: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "12px",
                          }}
                        >
                          {t(configKey)}
                        </Typography>
                      </Box>
                    </th>
                  ))}
                  <th
                    style={{
                      whiteSpace: "normal",
                      width: "150px",
                    }}
                  >
                    <Tooltip title={t("Options")}>
                      <>
                        {" "}
                        {/*  <Box>
                          <Icons
                            width="60"
                            name="Pictos Weevapp_Surface max "
                          />
                        </Box>*/}
                        <Typography>{t("Options")}</Typography>
                      </>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((room) => (
                  <tr key={room.id}>
                    <td>{room.nom_salle}</td>
                    <td>{room.capacite_max}</td>
                    <td>{room.superficie_salles}</td>
                    {Object.keys(salleConfig).map((configKey) => {
                      const config = room.configurations.find(
                        (conf) => conf.type === configKey,
                      );
                      return (
                        <td key={configKey}>
                          {config ? parseInt(config.capacite) || "n/c" : "n/c"}
                        </td>
                      );
                    })}
                    <td>
                      {Object.keys(room.options).map((key) =>
                        room.options[key] ? (
                          <Tooltip title={t(key)}>
                            <span>{salleConfigOptions[key]}</span>
                          </Tooltip>
                        ) : null,
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </>
      )}
    </Box>
  );
};

export default Rooms;
