import React from "react";
import {
	Box,
	ListItemDecorator,
	ListItemContent,
	Button,
	ListItemButton,
	ListItem,
	Divider,
	List,
} from "@mui/joy";
import { Logo } from "./partials";
import {
	Home,
	KeyboardArrowRight,
	KeyboardArrowDown,
	VisibilityOutlined,
	Diversity3,
	DashboardOutlined,
	EventOutlined,
	DinnerDining,
	ImportContacts,
	LogoutOutlined,
	Brush,
	FormatListBulletedOutlined,
	AccountCircleOutlined,
	PowerSettingsNewOutlined,
	SettingsOutlined,
	Article,
	Surfing,
	QrCode,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Helpers from "../../../../../utils/Helpers";
import { Collapse } from "@mui/material";
import useAuthProfile from "../../../../../containers/authentification/hooks/useAuthProfile";

const Menu = ({ style, openMenu, setOpenMenu, id }) => {
	const items = [
		{
			open: false,
			label: "place_details_menu",
			Icon: Article,
			path: `/places/edit/${id}`,
		},

		{
			open: false,
			label: "settings",
			Icon: SettingsOutlined,
			path: `/places/${id}/settings`,
		},

		{ label: "", Icon: Divider, path: `` },
	];

	const { logout } = useAuthProfile();
	const { t } = useTranslation();
	const location = useLocation();
	const rootLocation = Helpers.uriParts(location.pathname, 0);
	const [Items, setItems] = React.useState(items);
	React.useEffect(() => {
		let items = [...Items];
		/*
		Items.map((item, i) => {
			if (rootLocation === item?.parent) {
				items[i] = { ...items[i], open: true };
				console.log(items[i]);
			}
		});*/
		setItems(items);
	}, [rootLocation]);

	const toggleList = (index) => {
		const item = Items[index];
		item.open = !item.open;
		const i = [...Items];
		i[index] = item;

		setItems(i);
	};

	const subItems = [
		{
			label: "visualize",
			path: `/places/view/${id}`,
			Icon: VisibilityOutlined,
			color: "info",
		},
		{
			label: "out_editor",
			path: "/places/",
			Icon: LogoutOutlined,
			color: "warning",
		},
	];

	return (
		<Box style={style} sx={(theme) => ({ background: "white" })}>
			<Logo />
			<List sx={{ p: 2, maxHeight: "52%", overflow: "auto" }}>
				{Items.map((Item, index) => (
					<ListItem
						sx={(theme) => ({
							mb: 1,
							flexWrap: "wrap",
							flexDirection: "column",
							alignItems: "flex-start",
							background:
								Item.parent === rootLocation &&
								Item.open &&
								theme.vars.palette.primary[500],
						})}
					>
						<ListItemButton
							disabled={Item?.disabled}
							onClick={() => toggleList(index)}
							variant={
								location.pathname === Item.path
									? "solid"
									: "plain"
							}
							selected={location.pathname === Item.path}
							component={NavLink}
							to={Item.path}
							sx={(theme) => ({
								"&.Mui-selected": {
									background: theme.vars.palette.primary[600],
									"&:hover": {
										background:
											theme.vars.palette.primary[200],
									},
								},
								width: "100%",
								color:
									Item.parent === rootLocation &&
									Item.open &&
									"white",
							})}
						>
							<ListItemDecorator>
								{Item.Icon && (
									<Item.Icon
										sx={{
											color:
												Item.parent === rootLocation &&
												Item.open &&
												"white",
										}}
									/>
								)}
							</ListItemDecorator>
							<ListItemContent>{t(Item.label)}</ListItemContent>
							{Item?.childrens && (
								<>
									{Item.open ? (
										<KeyboardArrowDown />
									) : (
										<KeyboardArrowRight />
									)}
								</>
							)}
						</ListItemButton>

						{Item?.childrens && Item.open && (
							<List sx={{ p: 2 }}>
								{Item.childrens.map((child) => (
									<ListItem
										sx={{
											mb: 1,
											textAlign: "center",
										}}
									>
										<ListItemButton
											sx={{
												textAlign: "center",
												"&:hover": {
													background: "none",
													color:
														Item.parent ===
															rootLocation &&
														"white",
													fontWeight: "bold",
												},
												color:
													Item.parent ===
														rootLocation && "white",

												fontWeight:
													location.pathname ===
														child.path && "bold",
											}}
											selected={
												location.pathname === child.path
											}
											component={NavLink}
											to={child.path}
										>
											<ListItemContent>
												{t(child.label)}
											</ListItemContent>
										</ListItemButton>
									</ListItem>
								))}
							</List>
						)}
					</ListItem>
				))}
			</List>
			<List sx={{ p: 2, position: "absolute", width: "100%", bottom: 0 }}>
				<Divider
					sx={{
						width: "60%",
						margin: "0 auto ",
						mb: 2,
					}}
				/>
				{subItems.map((Item) => (
					<ListItem
						sx={{
							mb: 1,
							flexWrap: "wrap",
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<Button
							color={Item.color}
							component={NavLink}
							selected={location.pathname === Item.path}
							onClick={Item?.action}
							to={Item.path}
							sx={{ width: "100%", ...Items.style }}
						>
							<ListItemDecorator>
								{Item.Icon && (
									<Item.Icon sx={{ color: "white" }} />
								)}
							</ListItemDecorator>
							<ListItemContent>{t(Item.label)}</ListItemContent>
						</Button>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default Menu;
