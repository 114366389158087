import React from "react";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { BedroomParent, KingBed, SingleBed, Bed } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Icons from "assets/icons/Icons";

const RoomsResume = ({
  nombre_couchage_indiv,
  capacite_en_twin,
  nombre_couchage_max,
  nombre_de_chambres,
}) => {
  const arr = [
    {
      label: "nombre_couchage_indiv",
      data: nombre_couchage_indiv ?? null,
      icon: <Icons name="Pictos Weevapp_Nb de single" />,
    },
    {
      label: "capacite_en_twin",
      data: capacite_en_twin ?? null,
      icon: <Icons name="Pictos Weevapp_Nb de twin" />,
    },
    {
      label: "nombre_couchage_max",
      data: nombre_couchage_max ?? null,
      icon: <Icons name="Pictos Weevapp_Couchage max" />,
    },
    {
      label: "nombre_de_chambres",
      data: nombre_de_chambres ?? null,
      icon: <Icons name="Pictos Weevapp_Nb de chambres" />,
    },
  ];
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Typography
        level="h5"
        color="primary"
        sx={{ textAlign: "center", mb: 3 }}
      >
        {t("hebergement")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
        }}
      >
        {arr.map((room) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "row",
              }}
            >
              <Box>{room.icon}</Box>
              <Box>
                <Typography sx={{}}>
                  <b> {room.data ?? null}</b> {t(room.label)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RoomsResume;
