import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the Place class.
 * @constructor
 * @param {string} params.nom - Nom du lieu
 * @param {Array<string>} params.cadre - Cadre du lieu
 * @param {string} params.nombre_couchage_indiv - Nombre de couchages individuels
 * @param {string} params.pays - Pays du lieu
 * @param {string} params.nbr_salle_plus_50 - Nombre de salle de plus de 50m2
 * @param {string} params.nbr_salle_minus_50 - Nombre de salle de moins de 50m2
 * @param {Array<string>} params.sectionsVisibility - Visibilité des sections
 * @param {boolean} params.publish - Publié ou non
 * @param {Array<Object>} params.rooms - Liste des salles
 * @param {string} params.nombre_de_chambres - Nombre total de chambres
 * @param {string} params.hebergement_proximite - Hébergements à proximité
 * @param {string} params.type_lieu - Type de lieu
 * @param {string} params.groupId - Id du group
 * @param {Object} params.coordonnees_gps - Coordonnées GPS au format : {lng: 24.24234, lat: 24.242242}
 * @param {Array<string>} params.label_rse - Labels RSE
 * @param {string} params.description_hebergement - Description des espaces d'hébergement
 * @param {Array<Object>} params.espaces - Espaces
 * @param {string} params.featured_image - Image de mise en avant du lieu (URL)
 * @param {Array<string>} params.images - Images de la galerie du lieu (URLs)
 * @param {string} params.tarifs_journee_etude - Tarifs pour la journée d'études
 * @param {string} params.description_salles - Description des salles
 * @param {string} params.description_rse - Description des actions RSE
 * @param {Array<Object>} params.bedrooms - Liste des chambres
 * @param {string} params.uid - UID du créateur
 * @param {string} params.fromPlaceId - UID du créateur
 * @param {string} params.nombre_couchage_max - Nombre maximum de couchages
 * @param {string} params.ville - Ville du lieu
 * @param {Array<Object>} params.team_building - Liste des activités de team building
 * @param {Array<string>} params.activites_loisir - Liste des activités de loisir
 * @param {Object} params.created_at - Date de création
 * @param {Array<Object>} params.restauration - Espaces de restauration
 * @param {string} params.adresse - Adresse
 * @param {string} params.distance_points_interet - Points d'intérêt proches
 * @param {string} params.code_postal - Code postal
 * @param {string} params.tarifs_seminaire_residentiel - Tarifs pour un séminaire résidentiel
 * @param {string} params.description - Description du lieu
 * @param {Array<string>} params.equipement_technique - Liste des équipements techniques disponibles
 * @param {Array<string>} params.tags - Tags
 * @param {string} params.region - Région
 * @param {Array} params.actions_rse - listes des actions RSE
 * @param {string} params.capacite_en_twin - Capacité maximale d'hébergement en twin
 * @param {Object} params.modified_at - Date de modification
 */

class Place {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("places");
    }

    async getPlaces(clause, onUpdate) {
        try {
            const place = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return place;
        } catch (error) {
            console.error("Failed to fetch place:", error);
            throw error;
        }
    }

    async getPlacesPagination(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
            throw error;
        }
    }

    async getPlace(id) {
        try {
            const place = await this.firestoreService.getDocument(id);
            return place;
        } catch (error) {
            console.error("Failed to fetch place:", error);
            throw error;
        }
    }

    async addPlace() {
        try {
            const place = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: place.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add place:", error);
            throw error;
        }
    }

    async deletePlace(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete place:", error);
            throw error;
        }
    }

    async updatePlace(id) {
        try {
            const updatedPlace = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedPlace;
        } catch (error) {
            console.error("Failed to update place:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            nom: this.nom,
            cadre: this.cadre,
            nombre_couchage_indiv: this.nombre_couchage_indiv,
            pays: this.pays,
            publish: this.publish,
            rooms: this.rooms,
            nombre_de_chambres: this.nombre_de_chambres,
            hebergement_proximite: this.hebergement_proximite,
            type_lieu: this.type_lieu,
            coordonnees_gps: this.coordonnees_gps,
            actions_rse: this.actions_rse,
            label_rse: this.label_rse,
            description_hebergement: this.description_hebergement,
            espaces: this.espaces,
            featured_image: this.featured_image,
            images: this.images,
            tarifs_journee_etude: this.tarifs_journee_etude,
            description_salles: this.description_salles,
            description_rse: this.description_rse,
            bedrooms: this.bedrooms,
            nbr_salle_plus_50: this.nbr_salle_plus_50,
            nbr_salle_minus_50: this.nbr_salle_minus_50,
            uid: this.uid,
            nombre_couchage_max: this.nombre_couchage_max,
            ville: this.ville,
            team_building: this.team_building,
            activites_loisir: this.activites_loisir,
            fromPlaceId: this.fromPlaceId,
            created_at: this.created_at,
            restauration: this.restauration,
            adresse: this.adresse,
            groupId: this.groupId,
            distance_points_interet: this.distance_points_interet,
            code_postal: this.code_postal,
            tarifs_seminaire_residentiel: this.tarifs_seminaire_residentiel,
            description: this.description,
            sectionsVisibility: this.sectionsVisibility,
            equipement_technique: this.equipement_technique,
            tags: this.tags,
            region: this.region,
            capacite_en_twin: this.capacite_en_twin,
            modified_at: this.modified_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Place;
